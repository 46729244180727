import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, withStyles } from '@material-ui/core'
import React from 'react'

export const DuotoneButton = withStyles((theme) => ({
  root: {
    ...theme.palette.duotone
  }
}))(({ icon, ...props }) => (
  <Button
    variant='outlined'
    startIcon={icon && <FontAwesomeIcon icon={icon} />}
    {...props}
  />
))
