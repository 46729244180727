import { withStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Blurhash } from 'react-blurhash'

const BlurhashDisplay = withStyles((theme) => ({
  root: {
    position: 'absolute !important',
    top: 0,
    left: 0,
    width: '100% !important',
    height: '100% !important',
    '& canvas': {
      objectFit: 'cover'
    }
  }
}))(({ classes, image }) => {
  return Boolean(image && image.blurhash && image.blurhash.length > 6) ? (
    <Blurhash
      className={classes.root}
      hash={image.blurhash}
      resolutionX={32}
      resolutionY={32}
    />
  ) : null
})

export const ImageDisplay = withStyles((theme) => ({
  rootImageDisplay: {
    position: 'relative',
    backgroundImage: ({ image }) =>
      image && image.url ? 'none' : theme.palette.background.gradient,
    '&:before': {
      content: '""',
      display: 'block',
      paddingTop: ({ ratio }) => (ratio ? `calc(100% * ${ratio})` : '100%')
    },
    '&>img': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: ({ pos }) => {
        const getPer = (v) => (typeof v === 'number' ? `${v}%` : v)
        return pos &&
          (pos.left > -1 ||
            pos.top > -1 ||
            pos.left.includes('%') ||
            pos.top.includes('%'))
          ? `${getPer(pos.left)} ${getPer(pos.top)}`
          : '50% 50%'
      }
    }
  },
  normal: {
    width: '100%',
    '&>img': {
      maxWidth: '100%',
      width: '100%',
      objectFit: 'cover',
      zIndex: 1
    }
  }
}))(({ classes, image, pos, ratio, imgProps, ...props }) => {
  const [srcset, setSrcset] = useState('')
  const [isLoad, setIsLoad] = useState(false)

  useEffect(() => {
    if (typeof image === 'object') {
      let srcset = []
      if (props.size && props.size === 'large') {
        if (image.large || image.original) {
          srcset.push(`${image.large || image.original} 1280w`)
        }
      } else {
        if (image.large || image.url || image.original) {
          srcset.push(`${image.large || image.url || image.original} 1280w`)
        }
        if (image.medium) {
          srcset.push(`${image.medium} 960w`)
        }
        if (image.thumbnail) {
          srcset.push(`${image.thumbnail} 704w`)
        }
      }
      setSrcset(srcset.join(', '))
    }
  }, [image])

  if (ratio) {
    return (
      <div className={classes.rootImageDisplay} {...props}>
        <BlurhashDisplay image={image} />
        {Boolean(image && image.thumbnail) && (
          <img src={image.thumbnail} srcSet={srcset} {...imgProps} />
        )}
      </div>
    )
  } else {
    return (
      <div className={classes.normal} {...props}>
        {isLoad === false && <BlurhashDisplay image={image} />}
        {Boolean(image && image.thumbnail) && (
          <img
            src={image.thumbnail}
            srcSet={srcset}
            {...imgProps}
            onLoad={() => setIsLoad(true)}
          />
        )}
      </div>
    )
  }
})
